<template>

  <div style="background-color: var(--white);">
    <section class="selfcare-title d-flex">
      <div>
        <a class="btn btn-link btn-sm" style="color: #000" @click="goBack()">
          <svg fill="#000" class="material-design-icon__svg" width="15" height="15" viewBox="0 0 24 24">
            <path d="M20,11V13H8L13.5,18.5L12.08,19.92L4.16,12L12.08,4.08L13.5,5.5L8,11H20Z"></path>
          </svg>
        </a>
      </div>
      <div class="text-dark width-available text-center">
        <router-link to="/">
          <img style="float: left; width: 110px" src="/img/chopLifeLogo.png" class="top-header-logo img-fluid"
            alt="Chopbet Logo" />
        </router-link>
      </div>
    </section>
    <div class="px-2">
      <h4 class="text-dark">Frequently Asked Questions</h4>
      <section class="promo-card-wrapper mb-3">
        <div
          style="background-color: var(--light-red);padding: 4px;border-radius: 7px;padding: 15px;"
          class="text-dark" data-toggle="collapse" data-target="#1" aria-expanded="false" aria-controls="1">
          <strong>What is ChopBet</strong>
          <span style="float: right;">
            <img style="width: 10px;height: 10px;margin-bottom: 10px;" src="/menu/right.png" class="side-icon-svg">
          </span>
        </div>
        <div style="background-color: var(--light-red);padding: 15px;border-top: dotted 1px black;" class="promo-content text-dark collapse show" id="1">
          ChopBet is a leading betting site in cote d'ivore, offering a wide range of sports betting options and casino
          games. We are licensed by the Betting Control and Licensing Board (BCLB) and are committed to providing a safe
          and enjoyable betting experience for our users.
        </div>
      </section>

      <section class="promo-card-wrapper mb-3">
        <div
        style="background-color: var(--light-red);padding: 4px;border-radius: 7px;padding: 15px;"
        class=" text-dark" data-toggle="collapse" data-target="#3" aria-expanded="false" aria-controls="3">
          <strong>How do I create an account?</strong>
          <span style="float: right;">
            <img style="width: 10px;height: 10px;margin-bottom: 10px;" src="/menu/right.png" class="side-icon-svg">
          </span>
        </div>
        <div style="background-color: var(--light-red);padding: 15px;border-top: dotted 1px black;" class="promo-content text-dark collapse" id="3">
          //
        </div>
      </section>
      <section class="promo-card-wrapper mb-3">
        <div
          style="background-color: var(--light-red);padding: 4px;border-radius: 7px;padding: 15px;"
          class=" text-dark" data-toggle="collapse" data-target="#4" aria-expanded="false" aria-controls="4">
          <strong>How can I contact ChopBet customer support?</strong>
          <span style="float: right;">
            <img style="width: 10px;height: 10px;margin-bottom: 10px;" src="/menu/right.png" class="side-icon-svg">
          </span>
        </div>
        <div style="background-color: var(--light-red);padding: 15px;border-top: dotted 1px black;" class="promo-content text-dark collapse" id="4">
          //
        </div>
      </section>
      <section class="promo-card-wrapper mb-3">
        <div
          style="background-color: var(--light-red);padding: 4px;border-radius: 7px;padding: 15px;"
          class=" text-dark" data-toggle="collapse" data-target="#5" aria-expanded="false" aria-controls="5">
          <strong>What is the minimum amount that I can deposit?</strong>
          <span style="float: right;">
            <img style="width: 10px;height: 10px;margin-bottom: 10px;" src="/menu/right.png" class="side-icon-svg">
          </span>
        </div>
        <div style="background-color: var(--light-red);padding: 15px;border-top: dotted 1px black;" class="promo-content text-dark collapse" id="5">
          //
        </div>
      </section>
      <section class="promo-card-wrapper mb-3">
        <div
          style="background-color: var(--light-red);padding: 4px;border-radius: 7px;padding: 15px;"
          class=" text-dark" data-toggle="collapse" data-target="#6" aria-expanded="false" aria-controls="6">
          <strong>How do I reset my password?</strong>
          <span style="float: right;">
            <img style="width: 10px;height: 10px;margin-bottom: 10px;" src="/menu/right.png" class="side-icon-svg">
          </span>
        </div>
        <div style="background-color: var(--light-red);padding: 15px;border-top: dotted 1px black;" class="promo-content text-dark collapse" id="6">
          //
        </div>
      </section>

      <section class="promo-card-wrapper mb-3">
        <div
          style="background-color: var(--light-red);padding: 4px;border-radius: 7px;padding: 15px;"
          class=" text-dark" data-toggle="collapse" data-target="#7" aria-expanded="false" aria-controls="7">
          <strong>How do I place bets?</strong>
          <span style="float: right;">
            <img style="width: 10px;height: 10px;margin-bottom: 10px;" src="/menu/right.png" class="side-icon-svg">
          </span>
        </div>
        <div style="background-color: var(--light-red);padding: 15px;border-top: dotted 1px black;" class="promo-content text-dark collapse" id="7">
          //
        </div>
      </section>

      <section class="promo-card-wrapper mb-3">
        <div
          style="background-color: var(--light-red);padding: 4px;border-radius: 7px;padding: 15px;"
          class=" text-dark" data-toggle="collapse" data-target="#8" aria-expanded="false" aria-controls="7">
          <strong>What type of bets can I place on ChopBet?</strong>
          <span style="float: right;">
            <img style="width: 10px;height: 10px;margin-bottom: 10px;" src="/menu/right.png" class="side-icon-svg">
          </span>
        </div>
        <div style="background-color: var(--light-red);padding: 15px;border-top: dotted 1px black;" class="promo-content text-dark collapse" id="8">
          //
        </div>
      </section>

      <section class="promo-card-wrapper mb-3">
        <div
          style="background-color: var(--light-red);padding: 4px;border-radius: 7px;padding: 15px;"
          class=" text-dark" data-toggle="collapse" data-target="#9" aria-expanded="false" aria-controls="8">
          <strong>How will I know if my bet has been placed successfully</strong>
          <span style="float: right;">
            <img style="width: 10px;height: 10px;margin-bottom: 10px;" src="/menu/right.png" class="side-icon-svg">
          </span>
        </div>
        <div style="background-color: var(--light-red);padding: 15px;border-top: dotted 1px black;" class="promo-content text-dark collapse" id="9">
          //
        </div>
      </section>
    </div>
  </div>

</template>

<script>

export default {
  name: 'Faq',
  components: {
  },
  mounted: function () {

    this.$store.dispatch("setCurrentPage", "terms");
    this.reloadProfile();

  }

}
</script>